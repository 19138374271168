/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    code: "code",
    blockquote: "blockquote",
    h3: "h3",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "function-to-input-a-single-number",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function-to-input-a-single-number",
    "aria-label": "function to input a single number permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function to Input a single number"), "\n", React.createElement(_components.p, null, "For Competitive Programming in Rust"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "In Competitive Programming, it is generally the very first step to take a number as an input ", React.createElement(_components.strong, null, "and store it as a number"), " for multiple operations, like addition, subtraction, multiplication, division etc."), "\n", React.createElement(_components.p, null, "So, it is better to make a function and just call it instead of rewriting it again and again. It should be ", React.createElement(_components.strong, null, "included in your template")), "\n", React.createElement(_components.h2, {
    id: "what-are-we-doing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-we-doing",
    "aria-label": "what are we doing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are we doing"), "\n", React.createElement(_components.p, null, "In Rust, and most other programming languages, ", React.createElement(_components.strong, null, "input is taken as string of characters"), ". So, it is necessary to convert the string into desired numerical data type like ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "i8, u8 , i16, u16, isize, usize, f32, f64")), " etc."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " In this program, we will assume that ", React.createElement(_components.strong, null, "integers are in different lines"), " and only 1 integer per line is allowed. For multiple integers in a single line, see ", React.createElement(_components.a, {
    href: "/basic-programs/input-array-of-numbers/"
  }, "Function to Input array of numbers")), "\n", React.createElement(_components.h2, {
    id: "simple-multiplication-program",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#simple-multiplication-program",
    "aria-label": "simple multiplication program permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Simple Multiplication Program"), "\n", React.createElement(_components.p, null, "So, let's start with designing a multiplication program. In this program, we will take two integers and output will be the product of two numbers."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example :"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Input :")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "23 ", React.createElement("br"), "\n34"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Expected Output :")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "782"), "\n"), "\n", React.createElement(_components.h3, {
    id: "1-declaring-function",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-declaring-function",
    "aria-label": "1 declaring function permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1. Declaring function"), "\n", React.createElement(_components.p, null, "As you might already know, functions are declared in rust using ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "fn")), " keyword. Also, our function should return the input number. So, our function should look like"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn take_int() -> usize {\n // Function body here\n}\n")), "\n", React.createElement(_components.p, null, "You can replace ", React.createElement(_components.code, null, "usize"), " with any data type you want, provide it can be parsed."), "\n", React.createElement(_components.h3, {
    id: "2-declaring-a-string",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-declaring-a-string",
    "aria-label": "2 declaring a string permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2. Declaring a string"), "\n", React.createElement(_components.p, null, "Now, we need a string that is used to store the input. Let us name it ", React.createElement(_components.code, null, "input"), " itself"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let mut input = String::new();\n")), "\n", React.createElement(_components.h3, {
    id: "3-reading-into-the-string",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-reading-into-the-string",
    "aria-label": "3 reading into the string permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3. Reading into the string"), "\n", React.createElement(_components.p, null, "Now,  to obtain user input, we need to bring the ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "io")), " input/output library into scope. This is present in standard library, called ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "std"))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io;\n")), "\n", React.createElement(_components.p, null, "Now, we read and store input in ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "input")), " string  we created earlier."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "io::stdin()\n    .read_line(&mut input)\n    .unwrap();\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement(_components.code, null, "unwrap()")), " specifies that program will panic if, somehow, string cannot be read"), "\n", React.createElement(_components.h3, {
    id: "4-converting-string-into-a-number",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#4-converting-string-into-a-number",
    "aria-label": "4 converting string into a number permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "4. Converting String into a number"), "\n", React.createElement(_components.p, null, "Now, we finally convert the string input to number and return the value."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "return input\n    .trim()\n    .parse()\n    .unwrap();\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement(_components.code, null, ".trim()")), " is used to trim any leading or trailing whitespaces. ", React.createElement("br"), "\n", React.createElement(_components.strong, null, React.createElement(_components.code, null, ".parse()")), " is used to convert the string to number. Interestingly, you don't have to tell the data type in this case, because Rust automatically determines it using return data type. Cool, no? ", React.createElement("br"), "\n", React.createElement(_components.strong, null, React.createElement(_components.code, null, "unwrap()")), " specifies that program will panic if, string can not be converted to number. For example if you input ", React.createElement(_components.code, null, "12h"), ", ", React.createElement(_components.code, null, "23 34"), " it will panic because it is not convertible to number. You must enter 1 number per line."), "\n", React.createElement(_components.h3, {
    id: "5-putting-things-together",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#5-putting-things-together",
    "aria-label": "5 putting things together permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "5. Putting things together"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io;\n\nfn take_int() -> usize {\n    // Declare string\n    let mut input = String::new();\n\n    // Input string\n    io::stdin().read_line(&mut input).unwrap();\n\n    // Return number\n    return input.trim().parse().unwrap();\n}\n")), "\n", React.createElement(_components.h3, {
    id: "6-program-with-driver-code",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#6-program-with-driver-code",
    "aria-label": "6 program with driver code permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "6. Program With driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "\nuse std::io;\n\nfn take_int() -> usize {\n    // Declare string\n    let mut input = String::new();\n\n    // Input string\n    io::stdin().read_line(&mut input).unwrap();\n\n    // Return number\n    return input.trim().parse().unwrap();\n}\n\n// Driver code\n\npub fn main() {\n    let number1 = take_int();\n    let number2 = take_int();\n    println!(\"{}\", number1 * number2);\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input :")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "23 ", React.createElement("br"), "\n34"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output :")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "782"), "\n"), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "In this article, we designed a function that takes input from user and returns a number in any desired numerical data type. Here is the function"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io;\n\nfn take_int() -> usize {\n    let mut input = String::new();\n    io::stdin().read_line(&mut input).unwrap();\n    return input.trim().parse().unwrap();\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " We can change the data type of number returned by replacing ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "usize")), " in above function with desired data type."), "\n", React.createElement(_components.p, null, "We also designed a basic multiplication program, which takes input from user and output the product of these 2 numbers."), "\n", React.createElement(_components.p, null, "In next article, we will discuss how to ", React.createElement(_components.strong, null, "Input an array of numbers"), " in Rust, which is more tricky, but is often required in competitive programming."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Function to Input a single number - Rust Programming",
    description: "In Competitive Programming, it is generally the very first step to take a number as an input. We will design a function that takes input and returns a number in any desired numerical data type, and we will also make Simple Multiplication Program."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
